<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";
import FileInput from "../elements/file-input.vue";

export default {
    name: "skill-level-form",

    components: {FileInput, IconInput},

    emits: ['saved', 'update', 'add'],

    props: {
        item: {
            type: Object,
            required: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        },

        doNotSave: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'skillLevel/loading',
            states: 'skillLevel/states',
            errors: 'skillLevel/errors',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)
            if (!this.doNotSave) {
                return this.$store.dispatch(`skillLevel/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        this.$router.push(this.$t('routes./master-data/organizations/skill-levels/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                if (this.model.id) {
                    this.$emit('update', this.model);
                } else {
                    this.$emit('add', this.model);
                }
            }
        },

        setModel: function (model = {}) {
            const base = {}

            this.model = Object.assign(
                {},
                base,
                JSON.parse(JSON.stringify(model))
            )
        },

        setFile: function (event) {
            this.model.icon_file = event.target.files[0]
        }
    },

    watch: {
        item: function (value) {
            this.setModel(value)
        },

        model: {
            deep: true,
            immediate: true,
            handler: function (value) {
                this.$emit('update', Object.assign({}, value))
            },
        }
    },

    created() {
        this.setModel(this.item)
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('organizations::skill_levels.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('organizations::skill_levels.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="col-lg-9 mb-4">
                    <label
                        :class="{'text-danger': states.definition === false}"
                        class="form-label"
                        for="definition"
                    >{{ $t('organizations::skill_levels.columns.definition').ucFirst() }}</label
                    >
                    <textarea
                        id="definition"
                        v-model="model.definition"
                        :class="{'border-danger': states.definition === false}"
                        :placeholder="$t('organizations::skill_levels.placeholders.definition').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.definition === false}" class="invalid-feedback">
                        {{ errors.definition }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.color === false}"
                        class="form-label"
                        for="color"
                    >{{ $t('organizations::skill_levels.columns.color').ucFirst() }}</label
                    >
                    <input
                        id="color"
                        v-model="model.color"
                        :class="{'border-danger': states.color === false}"
                        :placeholder="$t('organizations::skill_levels.placeholders.color').ucFirst()"
                        class="form-control form-control-color"
                        name="color"
                        type="color"
                    />
                    <div :class="{'d-block': states.color === false}" class="invalid-feedback">{{ errors.color }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <icon-input
                        id="icon"
                        v-model="model.icon"
                        :class="{'border-danger': states.icon === false}"
                        :placeholder="$t('organizations::skill_levels.placeholders.icon').ucFirst()"
                        class="form-control"
                        name="icon"
                        :state="states.icon"
                        :error="errors.icon"
                    >{{ $t('organizations::skill_levels.columns.icon').ucFirst() }}</icon-input>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.file_file === false}"
                        class="form-label"
                        for="file_file"
                    >{{ $t('organizations::skill_levels.columns.icon_id').ucFirst() }}</label>
                    <file-input
                        id="file_file"
                        name="file_file"
                        :class="{'border-danger': states.file === false}"
                        :placeholder="$t('organizations::skill_levels.placeholders.icon_id').ucFirst()"
                        @change="setFile($event)"
                    ></file-input>
                    <div :class="{'d-block': states.file_file === false}" class="invalid-feedback">
                        {{ errors.file_file }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
